import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Tabs from "components/Tabs";

/**
 * ApplicantListTabs
 *
 * @param {Array} pageTabs
 * @param {Number} tabs
 * @param {Function} setSelectedTab
 */
const ApplicantListTabs = ({ tabs, selectedTab, setSelectedTab }) => (
  <Tabs
    data={tabs.map((tab, index) => (
      <FormattedMessage key={index} id={tab.title} />
    ))}
    selected={selectedTab}
    onSelect={setSelectedTab}
    withUrlParams
  />
);

ApplicantListTabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
};

export default ApplicantListTabs;
