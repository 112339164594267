import { GET_TEAM } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useSquad({ id, variables = {}, skip } = {}) {
  const { loading, data, error, refetch } = useQueryData({
    queryName: GET_TEAM(false, false),
    keyName: "team",
    skip,
    variables: {
      id,
      organization: true,
      assignments: true,
      limit: null,
      ...variables,
    },
  });

  return {
    loading,
    data: data?.team,
    error,
    refetch,
  };
}
