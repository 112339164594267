import React, { useState } from "react";
import PropTypes from "prop-types";
import { Header } from "components/DisplayBox";
import { FormattedMessage } from "react-intl";
import { shortenText } from "utils";
import styled from "@emotion/styled";
import { colors } from "style";
import { GET_CHALLENGES } from "graphql/queries";
import { CHALLENGES } from "constants";
import { useMissionRole } from "hooks";
import { CodeChallengesPicker } from "components/CodeChallenges";
import { Col, Row } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import Tags, { TAG_SIZE } from "components/Tags";
import { TAG_COLORS } from "components/Tags/Tag";
import { H3 } from "components/Text";
import { EditLink } from "components/Links";
import { EditButton } from "components/Buttons";

/**
 * RoleCodeChallenges
 *
 * @param {Array}    data
 * @param {Boolean}  isAdmin
 */
const RoleCodeChallenges = ({ data, isAdmin, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [challengeIds, setChallengeIds] = useState([]);
  const challengesExists = !!data?.challenges?.length;

  const { handleUpdateMissionRole } = useMissionRole({ skip: true });

  const rowOptions = ({ id }) => [
    {
      label: <FormattedMessage id="Global.Remove" />,
      icon: ICON_TYPE.trash,
      onClick: () =>
        handleUpdateMissionRole({
          id: data?.id,
          params: {
            challenges: data?.challenges.filter(({ id: challengeId }) => challengeId !== id).map(({ id }) => ({ id })),
          },
        }),
    },
  ];

  return (
    <>
      <Header {...props}>
        <H3>
          <FormattedMessage id="Missions.MissionRole.CodeChallenges" />
        </H3>
        {challengesExists && isAdmin && (
          <EditLink onClick={() => setShowForm(true)}>
            <FormattedMessage id="RoleCodeChallenges.EditButtonText" />
          </EditLink>
        )}
      </Header>
      {challengesExists ? (
        isAdmin ? (
          <DataTableProvider
            keyName="challenges"
            queryName={GET_CHALLENGES}
            variables={{
              filters: {
                ids: data?.challenges.map(({ id }) => id),
              },
            }}
            columnData={COLUMNS}
            rowOptions={rowOptions}
            paginationType={PAGINATION_TYPE.cursor}
            paginationProps={{
              countMessage: "Global.CodeChallenges.Count",
            }}
            disablePagination
          />
        ) : (
          <Tags data={data?.challenges} />
        )
      ) : (
        isAdmin && (
          <EditButton onClick={() => setShowForm(true)}>
            <FormattedMessage id="RoleCodeChallenges.EditButtonText" />
          </EditButton>
        )
      )}
      {isAdmin && showForm && (
        <CodeChallengesPicker
          show={showForm}
          onClose={() => setShowForm(false)}
          title={<FormattedMessage id="RoleCodeChallenges.CodeChallengesPicker.Title" />}
          info={<FormattedMessage id="RoleCodeChallenges.CodeChallengesPicker.Description" />}
          onSelect={setChallengeIds}
          onSubmit={() => {
            const existingChallenges = data?.challenges?.map((challenge) => challenge.id) || [];
            const allChallenges = [...new Set([...existingChallenges, ...challengeIds])];
            handleUpdateMissionRole({ id: data?.id, params: { challenges: allChallenges.map((id) => ({ id })) } });
            setShowForm(false);
          }}
          selectionInitialState={data?.challenges.map(({ id }) => id)}
          showSave
          selectable
          canSubmit
        />
      )}
    </>
  );
};

const COLUMNS = [
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnTitle" />,
    cell: (row) => (
      <Row>
        <div>
          <Icon type={ICON_TYPE[row?.icon || `data`]} size={ICON_SIZE.large} color="inherit" />
        </div>
        <Col gap="0.25rem">
          <Title>{shortenText(row?.name, 40)}</Title>
          <Type>{CHALLENGES[row?.type]}</Type>
          <Id>{row?.serviceId}</Id>
        </Col>
      </Row>
    ),
    width: "40%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnPrivateTitle" />,
    cell: (row) => row?.privateName,
    width: "15%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnSkills" />,
    cell: (row) => (
      <Row gap="0.4rem">
        {row?.skills?.length > 0 && (
          <Tags data={row?.skills} limit={2} unwrap size={TAG_SIZE.small} {...TAG_COLORS.pink} />
        )}
        {row?.disciplines?.length > 0 && (
          <Tags data={row?.disciplines} limit={2} unwrap size={TAG_SIZE.small} {...TAG_COLORS.purple} />
        )}
      </Row>
    ),
    width: "25%",
  },
  {
    label: <FormattedMessage id="CodeChallenges.CodeChallengeList.ColumnCategory" />,
    cell: (row) => row?.types?.length > 0 && <Tags data={row?.types} limit={2} />,
    width: "15%",
  },
];

const Type = styled.span`
  font-weight: 500;
`;

const Title = styled.span`
  font-weight: 500;
`;

const Id = styled.span`
  font-weight: 400;
  color: ${colors.grayAnatomyLight1};
`;

RoleCodeChallenges.propTypes = {
  data: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default RoleCodeChallenges;
