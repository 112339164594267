import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import {
  RoleDescription,
  RoleDetails,
  RelatedRoles,
  RoleCodeChallenges,
  RoleQuestionnaire,
} from "components/Missions/Roles";
import { MissionAboutClient } from "components/Missions";
import Card from "components/Card";
import { Grid } from "components/Containers";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";

/**
 * Mission Role Details
 */
const MissionRoleDetails = () => {
  const { isAdmin, canViewUnpublishedRoles } = useAuth();
  const { selectedRole, mission, loading, refetch } = useOutletContext();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Role.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Missions.Roles.Details.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={9}>
            <Grid>
              <Grid.col start={1} end={13}>
                <Card>
                  <RoleDescription isAdmin={isAdmin} role={selectedRole} isLoading={loading} showArcheTypes={isAdmin} />
                </Card>
              </Grid.col>
              {isAdmin && (
                <Grid.col start={1} end={13}>
                  <Card>
                    <RoleCodeChallenges data={selectedRole} loading={loading} isAdmin={isAdmin} />
                  </Card>
                </Grid.col>
              )}
              {isAdmin && (
                <Grid.col start={1} end={13}>
                  <Card>
                    <RoleQuestionnaire missionRole={selectedRole} loading={loading} refetch={refetch} />
                  </Card>
                </Grid.col>
              )}
            </Grid>
          </Grid.col>
          <Grid.col start={9} end={13}>
            <Grid>
              <Grid.col start={1} end={13}>
                <Card>
                  <RoleDetails role={selectedRole} mission={mission} isLoading={loading} />
                </Card>
              </Grid.col>
              <Grid.col start={1} end={13}>
                <Card>
                  <RelatedRoles
                    missionId={mission?.uuid}
                    isLoading={loading}
                    canViewUnpublishedRoles={canViewUnpublishedRoles}
                  />
                </Card>
              </Grid.col>
              <Grid.col start={1} end={13}>
                <Card>
                  <MissionAboutClient mission={mission} isLoading={loading} />
                </Card>
              </Grid.col>
            </Grid>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default MissionRoleDetails;
