import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { GET_MEMBER_FEEDBACK_FOR_TEAM } from "graphql/queries";
import { colors } from "style";
import Card from "components/Card";
import { Grid } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import { ProfileLink } from "components/Profile";
import { Tag } from "components/Tags";

/**
 * MemberFeedbackTable
 *
 * @param {String} teamId
 */
const MemberFeedbackTable = ({ teamId }) => {
  return (
    <Card>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            queryName={GET_MEMBER_FEEDBACK_FOR_TEAM}
            keyName="memberFeedbackForTeam"
            variables={{ teamId }}
            columns={[
              {
                label: <FormattedMessage id="MemberFeedbackTable.DataTable.ColumnProfile" />,
                cell: (row) => <ProfileLink profile={row.profile} withOverlay />,
                width: "20%",
              },
              {
                label: <FormattedMessage id="MemberFeedbackTable.DataTable.ColumnMonth" />,
                cell: (row) => row.monthVerbose,
                width: "10%",
              },
              {
                label: <FormattedMessage id="MemberFeedbackTable.DataTable.ColumnFeedback" />,
                cell: (row) => row.answer,
                width: "40%",
              },
              {
                label: <FormattedMessage id="MemberFeedbackTable.DataTable.ColumnSentiment" />,
                cell: (row) => <Tag color={SENTIMENT_TAG_COLORS[row.sentiment]}>{row.sentiment}</Tag>,
                width: "10%",
              },
              {
                label: <FormattedMessage id="MemberFeedbackTable.DataTable.ColumnSentimentScore" />,
                cell: (row) => row.sentimentScore,
                width: "10%",
              },
            ]}
            disablePagination
          />
        </Grid.col>
      </Grid>
    </Card>
  );
};

const SENTIMENT_TAG_COLORS = {
  Positive: colors.green,
  Neutral: colors.grayAnatomyBase,
  Negative: colors.red,
};

MemberFeedbackTable.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default MemberFeedbackTable;
