import { useEffect, useState } from "react";
import { GET_SQUAD_DYNAMICS } from "graphql/queries";
import { useQueryData } from "hooks";
import { FEEDBACK_OPTIONS } from "constants/index";

const keyName = "teamDynamics";

export default function useSquadDynamics({ teamId, date } = {}) {
  const [data, setData] = useState([]);
  const [questionText, setQuestionText] = useState();
  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_SQUAD_DYNAMICS,
    keyName,
    skip: !teamId,
    variables: {
      teamId,
      filters: {
        date,
      },
    },
  });

  useEffect(() => {
    if (response?.[keyName]) {
      setQuestionText(response?.[keyName]?.[0]?.question);
    }
  }, [response]);

  useEffect(() => {
    const tranformData = () =>
      response[keyName]
        .reduce((arr, squadDynamic) => {
          const { evaluatorProfile, evaluatedProfile, answer } = squadDynamic;
          const assesseeNameKey = evaluatedProfile.name;

          // Check if an entry for the evaluatorProfile already exists
          let squadDynamicEntry = arr.find((item) => item.profile && item.profile.id === evaluatorProfile.id);

          // If no entry exists for this evaluatorProfile, create a new one
          if (!squadDynamicEntry) {
            squadDynamicEntry = {
              profile: evaluatorProfile,
            };
            arr.push(squadDynamicEntry);
          }

          // Assign answer under each evaluatedProfile's name
          if (answer) {
            squadDynamicEntry[assesseeNameKey] = {
              value: FEEDBACK_OPTIONS[answer],
              label: answer,
            };
          }

          return arr;
        }, [])
        .sort((a, b) => a.profile?.name?.localeCompare(b.profile?.name));

    if (response?.[keyName]) {
      setData(tranformData());
    }
  }, [response]);

  return {
    loading,
    data,
    error,
    questionText,
  };
}
