import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import {
  ApplicantList,
  ApplicantListTabs,
  ApplicantListProgressMenu,
  ApplicantListFilters,
  ApplicantShareButton,
} from "components/Missions/Applicants";
import Card from "components/Card";
import { useApplicants, useAuth, useParamFunctions } from "hooks";
import { APPLICANT_TYPES } from "constants/index";
import { Grid } from "components/Containers";

/**
 * ApplicantListLayout
 *
 * @param {Boolean} showOnlyUnpublishedRoles
 * @param {Boolean} showAdminOnlyPages
 * @param {Boolean} initialTab
 * @param {Boolean} initialProgressMenu
 * @param {Boolean} hideFilters
 * @param {Boolean} hideTabs
 * @param {Boolean} hideProgressMenu
 * @param {Boolean} hideOrganizationFilter
 * @param {Boolean} hideMissionFilter
 * @param {Boolean} hidePublishedFilter
 * @param {Object}  defaultSelected
 */
const ApplicantListLayout = ({
  showOnlyUnpublishedRoles,
  showAdminOnlyPages,
  initialTab,
  initialProgressMenu,
  hideFilters,
  hideTabs,
  hideProgressMenu,
  hideOrganizationFilter,
  hideMissionFilter,
  hidePublishedFilter,
  defaultSelected,
  ...props
}) => {
  const { canViewUnpublishedRoles, roles } = useAuth();
  const { paramParse, setParams } = useParamFunctions();
  const filters = paramParse(KEYS.filters);
  const location = useLocation();
  const isPubFilter = filters?.[KEYS.isPublished];
  const locState = location.state;
  const [selectedProgressMenu, setSelectedProgressMenu] = useState(initialProgressMenu);
  const [selectedTab, setSelectedTab] = useState(initialTab || filters?.[KEYS.page] || 0);
  const pageTabs = PAGES.filter((item) => (showAdminOnlyPages && item.adminOnly) || !item.adminOnly);
  const applicantTabs = TABS.filter((item) => (showAdminOnlyPages && item.adminOnly) || !item.adminOnly);
  const [isPublished, setIsPublished] = useState(isPubFilter === undefined ? !canViewUnpublishedRoles : !!isPubFilter);

  const [selected, setSelected] = useState({
    missionId: filters?.[KEYS.missionId] || locState?.missionId || defaultSelected?.missionId,
    missionRoleId: filters?.[KEYS.missionRoleId] || locState?.missionRoleId || defaultSelected?.missionRoleId,
    organizationId: filters?.[KEYS.organizationId] || locState?.organizationId || defaultSelected?.organizationId,
    applicantState: filters?.[KEYS.applicantState]
      ? filters?.[KEYS.applicantState]
      : defaultSelected?.applicantState || APPLICANT_TYPES.applied,
  });

  const { data, error, count, paginationType, resultsPerPage, ...rest } = useApplicants({
    ...selected,
    published: showOnlyUnpublishedRoles ? false : isPublished, // Show unpublished roles for showcase and client
    resultsPerPage,
  });

  /**
   * @description Change the applicant state when the selected tab changes
   */
  useEffect(() => {
    const newState =
      selectedTab === 0 ? applicantTabs.flat()?.[selectedProgressMenu]?.type : pageTabs[selectedTab]?.type;

    if (selected.applicantState !== newState) {
      setSelected((prev) => ({ ...prev, applicantState: newState }));
    }
  }, [selectedTab, selectedProgressMenu, pageTabs, applicantTabs]);

  /**
   * @description Update the URL params when data changes
   */
  useEffect(() => {
    setParams(KEYS.filters, {
      [KEYS.missionId]: selected?.missionId,
      [KEYS.missionRoleId]: selected?.missionRoleId,
      [KEYS.organizationId]: selected?.organizationId,
      [KEYS.isPublished]: isPublished,
      [KEYS.tab]: selectedProgressMenu,
      [KEYS.applicantState]: selected?.applicantState,
      [KEYS.page]: selectedTab,
    });

    // Reset to page 1 on filter
    if (count?.total) {
      rest?.handlePageChange(1, resultsPerPage);
    }
  }, [selected, isPublished, selectedProgressMenu, selectedTab, count?.total]);

  return (
    <Grid>
      {!hideFilters && (
        <Grid.col start={1} end={13}>
          <ApplicantListFilters
            hideOrganizationFilter={hideOrganizationFilter}
            hideMissionFilter={hideMissionFilter}
            hidePublishedFilter={hidePublishedFilter}
            {...{
              selected,
              setSelected,
              isPublished,
              setIsPublished,
            }}
          />
        </Grid.col>
      )}
      <Grid.col start={1} end={13}>
        <Card>
          <Grid>
            {!hideTabs && (
              <Grid.col start={1} end={13}>
                <ApplicantListTabs
                  {...{
                    tabs: hideProgressMenu ? [] : pageTabs,
                    selectedTab,
                    setSelectedTab,
                  }}
                />
              </Grid.col>
            )}
            {!hideProgressMenu && selectedTab === 0 && (
              <Grid.col start={1} end={13}>
                <ApplicantListProgressMenu
                  {...{
                    applicantTabs,
                    count,
                    selectedProgressMenu,
                    setSelectedProgressMenu,
                  }}
                />
              </Grid.col>
            )}
            <Grid.col start={1} end={13}>
              <ApplicantList
                {...{
                  roles,
                  data,
                  error,
                  resultsPerPage,
                  paginationType,
                  selectedState: selected,
                  totalCount: count?.total,
                  ...rest,
                  ...props,
                }}
              />
            </Grid.col>
          </Grid>
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <ApplicantShareButton
          missionRoleSlug={data && data[0]?.missionRole?.slug}
          applicantType={selected?.applicantState}
          missionId={selected?.missionId}
          missionRoleId={selected?.missionRoleId}
        />
      </Grid.col>
    </Grid>
  );
};

const KEYS = {
  filters: "f",
  missionId: "missionId",
  missionRoleId: "missionRoleId",
  organizationId: "organizationId",
  isPublished: "isPublished",
  applicantState: "applicantState",
  page: "page",
  tab: "tab",
};

const PAGES = [
  {
    title: "Missions.Applicants.TabTitleApplicants",
  },
  {
    title: "Missions.Applicants.TabTitleRecommended",
    type: APPLICANT_TYPES.recommended,
  },
];

const TABS = [
  {
    title: "Missions.Applicants.TabTitleInvited",
    adminOnly: true,
    countField: "invited",
    type: null,
  },
  {
    title: "Missions.Applicants.TabTitleApplied",
    type: APPLICANT_TYPES.applied,
    countField: "applied",
  },
  {
    title: "Missions.Applicants.TabTitleShortlist",
    type: APPLICANT_TYPES.shortlisted,
    countField: "shortlisted",
  },
  {
    title: "Missions.Applicants.TabTitleInterviewing",
    type: APPLICANT_TYPES.interviewed,
    countField: "interviewing",
  },
  [
    {
      title: "Missions.Applicants.TabTitleSelected",
      type: APPLICANT_TYPES.selected,
      countField: "selected",
    },
    {
      title: "Missions.Applicants.TabTitleUnSelected",
      type: APPLICANT_TYPES.unselected,
      countField: "unselected",
    },
  ],
];

ApplicantListLayout.defaultProps = {
  initialTab: 0,
  initialProgressMenu: 1,
};

ApplicantListLayout.propTypes = {
  showOnlyUnpublishedRoles: PropTypes.bool,
  showAdminOnlyPages: PropTypes.bool,
  initialTab: PropTypes.number,
  initialProgressMenu: PropTypes.number,
  hideFilters: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideProgressMenu: PropTypes.bool,
  hideOrganizationFilter: PropTypes.bool,
  hideMissionFilter: PropTypes.bool,
  hidePublishedFilter: PropTypes.bool,
  defaultSelected: PropTypes.object,
};

export default ApplicantListLayout;
