import React from "react";
import PropTypes from "prop-types";
import App from "App";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import PageTitle from "components/PageTitle";
import Card from "components/Card";
import { NarrowContainer, PageWrapper, Grid } from "components/Containers";
import { HeaderPublicGradient } from "components/Header";
import { colors, themes } from "style";
import { ApplyButton, RoleDescription } from "components/Missions/Roles";
import { MissionAbout } from "components/Missions";
import { useAuth, useMissionRole } from "hooks";

/**
 * Profile Page
 */
const PublicMissionRole = () => {
  const { id } = useParams();
  const { data: role, loading } = useMissionRole({ id, isPublicRole: true });
  const { isAuthenticated, canApplyForRolesOnBehalf } = useAuth();

  return (
    <App theme={themes.website}>
      <HeaderPublicGradient>
        <NarrowContainer>
          <PageWrapper>
            <Grid>
              <Grid.col start={1} end={13}>
                <PageTitle
                  css={styles.title}
                  title={role?.name}
                  actionBar={
                    <>
                      {isAuthenticated && (
                        <ApplyButton role={role} canApplyOnBehalf={canApplyForRolesOnBehalf}>
                          <FormattedMessage id={`Missions.Apply${canApplyForRolesOnBehalf ? '.OnBehalf' : ''}`} />
                        </ApplyButton>
                      )}
                    </>
                  }
                  loading={loading}
                />
              </Grid.col>
              <Grid.col start={1} end={13}>
                <Card>
                  <RoleDescription role={role} isLoading={loading} />
                </Card>
              </Grid.col> 
              <Grid.col start={1} end={13}>                
                <Card>
                  <MissionAbout mission={role?.mission} isLoading={loading} />
                </Card>
              </Grid.col>
            </Grid>
          </PageWrapper>
        </NarrowContainer>
      </HeaderPublicGradient>
    </App>
  );
};

const styles = {
  title: css`
    color: ${colors.white};
  `,
};

PublicMissionRole.propTypes = {
  id: PropTypes.number,
};

export default PublicMissionRole;
