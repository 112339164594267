import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS, QUESTION_FIELDS } from "graphql/fragments";

const GET_ASSESSMENT = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  query assessment(
    $id: ID!
    $allowedId: ID
    $resourceType: String
    $resourceId: String
    $skipAnswers: Boolean = false
  ) {
    assessment(id: $id, allowedId: $allowedId) {
      ...AssessmentFields
      answers(resourceType: $resourceType, resourceId: $resourceId) @skip(if: $skipAnswers)
      questions(resourceType: $resourceType, resourceId: $resourceId) {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

export { GET_ASSESSMENT };
