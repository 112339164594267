import { useEffect, useState } from "react";
import { GET_PULSE } from "graphql/queries";
import { useQueryData } from "hooks";
import { FEEDBACK_OPTIONS } from "constants/index";

const keyName = "pulse";

export default function usePulse({ teamId, startDate, endDate } = {}) {
  const [data, setData] = useState([]);
  const [questionText, setQuestionText] = useState();
  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_PULSE,
    keyName,
    skip: !teamId,
    variables: {
      teamId,
      filters: {
        startDate,
        endDate,
      },
    },
  });

  useEffect(() => {
    if (response?.[keyName]) {
      setQuestionText(response?.[keyName]?.[0]?.question);
    }
  }, [response]);

  useEffect(() => {
    const transformData = () =>
      response[keyName]
        .reduce((arr, pulse) => {
          const { profile, answer, monthVerbose } = pulse;
          const monthYearKey = monthVerbose;

          // Find or create profile entry
          let pulseEntry = arr.find((entry) => entry.profile.id === profile.id);
          if (!pulseEntry) {
            pulseEntry = { profile: profile };
            arr.push(pulseEntry);
          }

          // Assign feedback by month-year
          if (answer) {
            pulseEntry[monthYearKey] = {
              value: FEEDBACK_OPTIONS[answer],
              label: answer,
            };
          }

          return arr;
        }, [])
        .sort((a, b) => a.profile?.name?.localeCompare(b.profile?.name));

    if (response?.[keyName]) {
      setData(transformData());
    }
  }, [response]);

  return {
    loading,
    data,
    error,
    questionText,
  };
}
