import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { Grid } from "components/Containers";
import ProgressMenu from "components/ProgressMenu";
import { Spinner } from "components/Loader";

/**
 * ApplicantListTabs
 *
 * @param {Array} applicantTabs
 * @param {Object} count
 * @param {Number} selectedProgressMenu
 * @param {Function} setSelectedProgressMenu
 */
const ApplicantListTabs = ({ applicantTabs, count, selectedProgressMenu, setSelectedProgressMenu }) => (
  <Grid>
    <Grid.col start={1} end={13}>
      <ProgressMenu
        items={transformData(applicantTabs, count)}
        selected={selectedProgressMenu}
        onSelect={setSelectedProgressMenu}
      />
    </Grid.col>
    <Grid.col start={1} end={13}>
      <hr />
    </Grid.col>
  </Grid>
);

const transformData = (tabs, counts) => tabs.map((item) => renderMenuTitle(item, counts));

const renderMenuTitle = (item, counts) => {
  // Handle nested arrays
  if (Array.isArray(item)) return { subItems: transformData(item, counts) };

  // Helper to render count with fallback
  const Count = () =>
    counts[item.countField] === undefined ? (
      <Spinner size="1rem" />
    ) : (
      <div css={styles.count}>({counts[item.countField]})</div>
    );

  // Handle top-level objects
  return {
    label: <FormattedMessage id={item.title} values={{ count: <Count /> }} />,
  };
};

const styles = {
  count: css`
    font-weight: 400;
  `,
};

ApplicantListTabs.propTypes = {
  applicantTabs: PropTypes.array,
  count: PropTypes.object,
  selectedProgressMenu: PropTypes.number,
  setSelectedProgressMenu: PropTypes.func,
};

export default ApplicantListTabs;
