import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "constants";
import Overlay from "components/Overlay";
import SquadCard from "./SquadCard";
import { PrimaryLink } from "components/Links";

/**
 * SquadLink
 *
 * @param {Object}  data
 * @param {Boolean} withOverlay
 */
const SquadLink = ({ data, withOverlay, ...props }) => {
  const Link = () => (
    <PrimaryLink to={LINKS.squad_profile(data?.slug)} routerEnabled>
      {data?.name}
    </PrimaryLink>
  );

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <Overlay
      asCardOverlay
      placement="auto"
      width={`30rem`}
      overlay={<SquadCard data={data} />}
      trigger={<Link />}
      delay={{ show: 1000 }}
      {...props}
    />
  );
};

SquadLink.propTypes = {
  data: PropTypes.object.isRequired,
  withOverlay: PropTypes.bool,
};

export default SquadLink;
