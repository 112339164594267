import { gql } from "@apollo/client";

const challengeResponseFields = `
  id
  profileId
  status
  score
  maxScore
  reportUrl
  type
  tasks
  structuredTasks
  startedAt
  completedAt
  cheatingFlag
  cheatingDetails
  challenge {
    id
    name
    icon
    types {
      id
      name
    }
  }
`;

const CHALLENGE_RESPONSES_FIELDS = gql`
  fragment ChallengeResponseFields on ChallengeResponse {
    ${challengeResponseFields}
  }
`;

export { CHALLENGE_RESPONSES_FIELDS };
