import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { DestructableButton, PrimaryButton, SecondaryButton } from "components/Buttons";
import { Select, TextInput } from "components/Form";
import { Col, Row } from "components/Containers";
import Card from "components/Card";
import { colors } from "style";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, StatusIcon, withIcon } from "components/Icon";
import { formatCurrency } from "utils";
import { CURRENCIES, RATE_FREQUENCIES } from "constants/index";
import { Spinner } from "components/Loader";

/**
 * ApplicantNegotiatedRate
 *
 * @param {Number}    rate
 * @param {Boolean}   loading
 * @param {Function}  onSave
 * @param {Function}  onRemove
 */
const ApplicantNegotiatedRate = ({ rate, loading, onSave, onRemove }) => {
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(rate?.amount);
  const [currency, setCurrency] = useState(rate?.currency);

  useEffect(() => {
    setValue(rate?.amount);
    setCurrency(rate?.currency);
  }, [rate]);

  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    onSave?.({ amount: parseFloat(value), currency: currency, frequency: RATE_FREQUENCIES.hourly });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(rate?.amount);
    setCurrency(rate?.currency);
  };

  return (
    <Card css={styles.container(rate?.amount)}>
      <Col gap="1rem">
        {loading ? (
          <Spinner size="1.5rem" />
        ) : (
          <Row css={styles.title}>
            <span>
              {rate?.amount ? (
                <Row>
                  <div>
                    <StatusIcon
                      type={ICON_TYPE.checkmark}
                      size={ICON_SIZE.medium}
                      color={colors.grayAnatomyLight2}
                      isComplete={true}
                    />
                  </div>
                  <span>
                    <FormattedMessage
                      id="Missions.Applicants.ApplicantNegotiatedRate.Rate"
                      values={{
                        rate: <b>{formatCurrency(rate?.amount, rate?.currency)}</b>,
                      }}
                    />
                  </span>
                </Row>
              ) : (
                <FormattedMessage id="Missions.Applicants.ApplicantNegotiatedRate.NoRate" />
              )}
            </span>
            {!isEditing && (
              <Row>
                <SecondaryButton onClick={handleEdit}>
                  <FormattedMessage
                    id={`Missions.Applicants.ApplicantNegotiatedRate.${rate?.amount ? `Edit` : `Add`}Rate`}
                  />
                </SecondaryButton>
                {rate?.amount && (
                  <DestructableButton onClick={onRemove}>
                    <FormattedMessage id="Global.Remove" />
                  </DestructableButton>
                )}
              </Row>
            )}
            {isEditing && (
              <Row>
                <div css={styles.form_item}>
                  <RateInput
                    ref={inputRef}
                    placeholder="Rate"
                    css={styles.input}
                    value={value}
                    onChange={(name, val) => setValue(val === "0" ? "" : val)}
                    autoFocus={true}
                  />
                </div>
                <div css={styles.form_item}>
                  <CurrencyInput
                    value={currency}
                    onChange={(name, val) => setCurrency(val)}
                    options={CURRENCIES}
                    placeholder="Currency"
                  />
                </div>
                <PrimaryButton onClick={handleSave} disabled={!value || !currency}>
                  <FormattedMessage id="Global.Save" />
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel}>
                  <FormattedMessage id="Global.Cancel" />
                </SecondaryButton>
              </Row>
            )}
          </Row>
        )}
      </Col>
    </Card>
  );
};

const RateInput = withIcon((props) => <TextInput {...props} type="number" />, {
  type: ICON_TYPE.dollarBill,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

const CurrencyInput = withIcon(Select, {
  type: ICON_TYPE.coin,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

const styles = {
  container: (rate) => css`
    background: ${rate ? colors.lightGreen : colors.grayAnatomyLight5};
    border-color: ${rate ? colors.green : colors.grayAnatomyLight4};
  `,
  form_item: css`
    width: 15rem;
  `,
  title: css`
    justify-content: space-between;
  `,
  note: css`
    font-weight: 600;
  `,
  input: css`
    order: 0;
  `,
};

ApplicantNegotiatedRate.propTypes = {
  rate: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ApplicantNegotiatedRate;
