import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { APPLICANT_LIST_COLUMNS } from "constants/index";

/**
 * Candidates Page
 */
const Candidates = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applicants.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <PageTitle title={<FormattedMessage id={`My Applications`} />} />
        <Grid topMargin>
          <Grid.col start={1} end={13}>
            <ApplicantListLayout
              showAdvancedOptions={false}
              showAdminOnlyPages={false}
              hideOrganizationFilter={true}
              hideMissionFilter={true}
              hideFilters={true}
              hideTabs={true}
              columns={[APPLICANT_LIST_COLUMNS.member, APPLICANT_LIST_COLUMNS.mission, APPLICANT_LIST_COLUMNS.applied]}
            />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Candidates;
