import { gql } from "@apollo/client";

const GET_ASSIGNMENTS = gql`
  query GetAssignments($filters: AssignmentFilter) {
    assignments(filters: $filters) {
      id
      buyingRate {
        amount
        currency
        frequency
      }
      offboardedAt
      onboardedAt
      team {
        slug
        name
        organization {
          slug
          name
        }
        mission {
          uuid
          name
        }
      }
    }
  }
`;

export { GET_ASSIGNMENTS };
