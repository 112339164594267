import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { Select, CheckBox } from "components/Form";
import { GET_ORGANIZATIONS } from "graphql/queries";
import { SORT } from "constants/index";
import { Grid } from "components/Containers";
import { TOGGLE_SIZE } from "components/Form/Toggle";
import { useMissionRoles, useMissions, useAuth } from "hooks";

/**
 * Filters
 * @param {Object} selected
 * @param {Function} setSelected
 * @param {Boolean} isPublished
 * @param {Function} setIsPublished
 * @param {Boolean} hideOrganizationFilter
 * @param {Boolean} hideMissionFilter
 * @param {Boolean} hideRoleFilter
 * @param {Boolean} hidePublishedFilter
 */
const ApplicantListFilters = ({
  selected,
  setSelected,
  isPublished,
  setIsPublished,
  hideOrganizationFilter,
  hideMissionFilter,
  hideRoleFilter,
  hidePublishedFilter,
}) => {
  const { isClient, isShowcase, canViewUnpublishedRoles } = useAuth();
  const isShowcaseOrClient = isClient || isShowcase;

  const { data: missionRoles, loading: loadingRoles } = useMissionRoles({
    missionId: selected?.missionId,
    filters: { published: isPublished },
    skipQuery: !selected?.missionId && !isShowcaseOrClient,
  });

  const { data: missions, loading: loadingMissions } = useMissions({
    filters: { organizationId: selected?.organizationId },
    skip: !selected?.organizationId,
  });

  const missionOptions = useMemo(() => (missions || []).map(({ name, id }) => ({ name, id })), [missions]);

  const roleOptions = useMemo(
    () =>
      (missionRoles || []).map(({ name, id, mission }) => ({
        name,
        id,
        missionId: mission?.id,
        organizationId: mission?.organization?.id,
      })),
    [missionRoles]
  );

  return (
    <Card>
      <Grid>
        {!hideOrganizationFilter && (
          <Grid.col colSpan={3}>
            <Select
              value={selected.organizationId}
              queryKeyName={"organizations"}
              queryName={GET_ORGANIZATIONS}
              placeholder="Organization"
              labelKey="name"
              queryVariables={{
                first: 50,
                orderBy: {
                  id: SORT.desc,
                },
              }}
              valueKey="id"
              onChange={(_, val) => {
                setSelected((prev) => ({
                  ...prev,
                  missionId: null,
                  missionRoleId: null,
                  organizationId: val,
                }));
              }}
              querySearchFieldName="search"
              noOptionsMessage="No organization found"
              isSearchable
              isClearable
            />
          </Grid.col>
        )}
        {!hideMissionFilter && (
          <Grid.col colSpan={3}>
            <Select
              options={missionOptions}
              loading={loadingMissions}
              disabled={!selected.organizationId}
              value={selected.missionId}
              placeholder={!selected.organizationId ? "Select organization first" : "Mission"}
              labelKey="name"
              valueKey="id"
              onChange={(_, val) => {
                setSelected((prev) => ({
                  ...prev,
                  missionId: val,
                  missionRoleId: null,
                }));
              }}
              noOptionsMessage="No mission found"
              isSearchable
              isClearable
            />
          </Grid.col>
        )}
        {!hideRoleFilter && (
          <Grid.col colSpan={3}>
            <Select
              options={roleOptions}
              disabled={!selected.missionId && !isClient}
              loading={loadingRoles}
              value={selected.missionRoleId}
              placeholder={!selected.missionId && !isClient ? "Select mission first" : "Role"}
              labelKey="name"
              valueKey="id"
              onChange={(_, val) => setSelected((prev) => ({ ...prev, missionRoleId: val }))}
              isClearable={!isShowcaseOrClient}
            />
          </Grid.col>
        )}
        {!hidePublishedFilter && (
          <Grid.col colSpan={3} alignCenter>
            <CheckBox
              value={isPublished}
              onChange={(_, val) => setIsPublished(val)}
              size={TOGGLE_SIZE.small}
              label={<FormattedMessage id={"Missions.Applicants.ToggleOpenRoles"} />}
              disabled={!canViewUnpublishedRoles}
            />
          </Grid.col>
        )}
      </Grid>
    </Card>
  );
};

ApplicantListFilters.propTypes = {
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  isPublished: PropTypes.bool,
  setIsPublished: PropTypes.func,
  hideOrganizationFilter: PropTypes.bool,
  hideMissionFilter: PropTypes.bool,
  hideRoleFilter: PropTypes.bool,
  hidePublishedFilter: PropTypes.bool,
};

export default ApplicantListFilters;
