import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS, QUESTION_FIELDS } from "graphql/fragments";

const UPDATE_ASSESSMENT = gql`
  ${ASSESSMENT_FIELDS}
  mutation UpdateAssessment($input: UpdateAssessmentInput!, $resourceType: String, $resourceId: String) {
    updateAssessment(input: $input) {
      assessment {
        answers(resourceType: $resourceType, resourceId: $resourceId)
        ...AssessmentFields
      }
    }
  }
`;

const ADD_ASSESSMENT_OPTION = gql`
  mutation addAssessmentOption($input: AddAssessmentOptionInput!) {
    addAssessmentOption(input: $input) {
      assessmentOption {
        value
      }
    }
  }
`;

const REMOVE_ASSESSMENT_OPTION = gql`
  mutation removeAssessmentOption($input: RemoveAssessmentOptionInput!) {
    removeAssessmentOption(input: $input) {
      id
    }
  }
`;

const ADD_MISSION_ROLE_ASSESSMENT_QUESTION = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  mutation AddAssessmentQuestion($input: AddAssessmentQuestionInput!) {
    addAssessmentQuestion(input: $input) {
      ...AssessmentFields
      questions(resourceType: "open") {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

const UPDATE_MISSION_ROLE_ASSESSMENT_QUESTION = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  mutation UpdateAssessmentQuestion($input: UpdateAssessmentQuestionInput!) {
    updateAssessmentQuestion(input: $input) {
      ...AssessmentFields
      questions(resourceType: "open") {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

const DETACH_MISSION_ROLE_ASSESSMENT_QUESTION = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  mutation DetachQuestionFromAssessment($input: DetachQuestionFromAssessmentInput!) {
    detachQuestionFromAssessment(input: $input) {
      ...AssessmentFields
      questions(resourceType: "open") {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

export {
  ADD_MISSION_ROLE_ASSESSMENT_QUESTION,
  DETACH_MISSION_ROLE_ASSESSMENT_QUESTION,
  UPDATE_MISSION_ROLE_ASSESSMENT_QUESTION,
  UPDATE_ASSESSMENT,
  ADD_ASSESSMENT_OPTION,
  REMOVE_ASSESSMENT_OPTION,
};
