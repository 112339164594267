import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Outlet, useParams } from "react-router-dom";
import { useAuth, useMissionRole, useTracking } from "hooks";
import { LINKS, PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import PageTitle, { LAYOUT } from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { NavList } from "components/Nav";
import { BannerShowcaseSignup } from "components/Banner";
import { ApplyButton, RoleAdvancedOptions } from "components/Missions/Roles";

/**
 * MissionRole
 */
const MissionRole = () => {
  const { isAdmin, isClient, isShowcase, canApplyForRolesOnBehalf } = useAuth();
  const [navItems, setNavItems] = useState([]);
  const { id, roleId } = useParams();
  const { trackPage } = useTracking();
  const { loading, data: selectedRole, error, refetch } = useMissionRole({ id: roleId });
  const mission = selectedRole?.mission;

  const MissionBoardBackLink = () => {
    if (isClient || isShowcase) {
      return LINKS.client_mission_board;
    } else {
      return LINKS.mission(mission?.uuid);
    }
  };

  useEffect(() => {
    setNavItems(pages(id, roleId, isAdmin).filter((page) => page.isPermissible));
  }, [id, roleId, isAdmin]);

  useEffect(() => {
    if (isShowcase) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.roleDetails].page_name, TRACKING_PAGE_VIEWS[PAGES.roleDetails]);
    }
  }, []);

  return (
    <>
      {(loading || selectedRole) && (
        <>
          <PageTitle
            backLink={
              <PrimaryLink to={MissionBoardBackLink()} routerEnabled>
                {isClient || isShowcase ? <FormattedMessage id="Missions.Mission.Role.Title" /> : mission?.name}
              </PrimaryLink>
            }
            loading={loading}
            title={selectedRole?.name}
            subtitle={
              <div
                css={css`
                  padding-top: 2rem;
                `}
              >
                <NavList pages={navItems} />
              </div>
            }
            layout={LAYOUT.horizontal}
            actionBar={
              <>
                <ApplyButton role={selectedRole} canApplyOnBehalf={canApplyForRolesOnBehalf}>
                  <FormattedMessage id={`Missions.Apply${canApplyForRolesOnBehalf ? ".OnBehalf" : ""}`} />
                </ApplyButton>
                <RoleAdvancedOptions roleId={selectedRole?.id} data={selectedRole} />
              </>
            }
            asHeader
          >
            <BannerShowcaseSignup
              css={css`
                padding: 4rem 0 0 0;
              `}
            />
          </PageTitle>
        </>
      )}
      <Outlet
        context={{
          selectedRole,
          loading,
          error,
          refetch,
          mission,
        }}
      />
    </>
  );
};

const defaultPageProps = {
  routerEnabled: true,
  end: false,
  isActive: true,
};

const pages = (missionId, roleId, isAdmin) => [
  {
    label: <FormattedMessage id="Missions.MissionRole.Tabs.Details" key="tab1" />,
    to: LINKS.mission_role_details(missionId, roleId),
    isPermissible: isAdmin,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.Tabs.Recommendations" key="tab2" />,
    to: LINKS.mission_role_recommendations(missionId, roleId),
    isPermissible: isAdmin,
    props: defaultPageProps,
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.Tabs.Applications" key="tab3" />,
    to: LINKS.mission_role_applications(missionId, roleId),
    isPermissible: isAdmin,
    props: defaultPageProps,
  },
];

export default MissionRole;
