import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { MissionInviteMember } from "components/Missions";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import { Grid } from "components/Containers";
import { useAuth } from "hooks";
import { APPLICANT_LIST_COLUMNS } from "constants/index";

/**
 * Candidates Page
 */
const Candidates = () => {
  const intl = useIntl();
  const { isAdmin } = useAuth();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applicants.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={<FormattedMessage id={`Missions.Applicants.H1`} />}
            actionBar={<MissionInviteMember />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ApplicantListLayout
            showAdvancedOptions={isAdmin}
            showAdminOnlyPages={isAdmin}
            hideOrganizationFilter={false}
            hideMissionFilter={false}
            hideFilters={false}
            columns={[
              APPLICANT_LIST_COLUMNS.member,
              APPLICANT_LIST_COLUMNS.mission,
              APPLICANT_LIST_COLUMNS.profileRate,
              APPLICANT_LIST_COLUMNS.hourlyRateRange,
              APPLICANT_LIST_COLUMNS.applicationRate,
              APPLICANT_LIST_COLUMNS.noticePeriod,
              APPLICANT_LIST_COLUMNS.profileAvailability,
              APPLICANT_LIST_COLUMNS.invited,
              APPLICANT_LIST_COLUMNS.applied,
              APPLICANT_LIST_COLUMNS.matchScore,
              APPLICANT_LIST_COLUMNS.matchScoreWithoutTooltip,
              APPLICANT_LIST_COLUMNS.rateScore,
              APPLICANT_LIST_COLUMNS.viewButton,
            ]}
          />
        </Grid.col>
      </Grid>
    </>
  );
};

export default Candidates;
