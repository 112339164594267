import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useQueryData, useToast, useAuth } from "hooks";
import {
  GET_MISSION_ROLE_APPLICANTS,
  GET_MISSION_ROLE_INVITATIONS,
  GET_MISSION_ROLE_RECOMMENDATIONS,
} from "graphql/queries";
import {
  UNSELECT_APPLICANT,
  SELECT_APPLICANT,
  INTERVIEW_APPLICANT,
  SHORTLIST_APPLICANT,
  ADD_MISSION_ROLE_INVITATION,
  MOVE_APPLICANT_TO_APPLIED,
} from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";
import { ICON_TYPE } from "components/Icon";
import { APPLICANT_TYPES, DIRECTION, MISSION_ROLE_APPLICANTS_PER_PAGE } from "constants/index";

/**
 * useApplicants
 *
 * @params {Number}   resultsPerPage
 * @params {String}   applicantState
 * @params {String}   missionId
 * @params {String}   missionRoleId
 * @params {String}   keyword
 * @params {String}   organizationId
 * @params {Boolean}  published
 * @params {Boolean}  skipQuery
 * @params {String}   paginationType
 * @params {Number}   currentPage
 */
export default function useApplicants({
  resultsPerPage = MISSION_ROLE_APPLICANTS_PER_PAGE,
  paginationType = PAGINATION_TYPE.classic,
  applicantState,
  missionId,
  missionRoleId,
  organizationId,
  keyword,
  published,
  skipQuery = false,
} = {}) {
  const { toast } = useToast();
  const { isAdmin } = useAuth();
  const [stateFilter, setStateFilter] = useState(applicantState === null ? {} : { state: applicantState });
  const [queryName, setQueryName] = useState(GET_MISSION_ROLE_APPLICANTS);
  const [keyName, setKeyName] = useState(QUERY_KEYNAMES.applicants);
  const [unselectedApplicant] = useMutation(UNSELECT_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [shortlistApplicant] = useMutation(SHORTLIST_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [selectedApplicant] = useMutation(SELECT_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [interviewApplicant] = useMutation(INTERVIEW_APPLICANT, {
    refetchQueries: ["missionRoleApplicants"],
  });
  const [inviteApplicant] = useMutation(ADD_MISSION_ROLE_INVITATION, {
    refetchQueries: ["missionRoleInvitations", "missionRoleRecommendations"],
  });
  const [moveApplicantToApplied] = useMutation(MOVE_APPLICANT_TO_APPLIED, {
    refetchQueries: ["missionRoleApplicants"],
  });

  useEffect(() => {
    switch (applicantState) {
      case null:
        return setQueryName(GET_MISSION_ROLE_INVITATIONS), setKeyName(QUERY_KEYNAMES.invitations);
      case APPLICANT_TYPES.recommended:
        return setQueryName(GET_MISSION_ROLE_RECOMMENDATIONS), setKeyName(QUERY_KEYNAMES.recommendations);
      default:
        return setQueryName(GET_MISSION_ROLE_APPLICANTS), setKeyName(QUERY_KEYNAMES.applicants);
    }
  }, [applicantState, setQueryName, setKeyName]);

  const handleSkip = applicantState === APPLICANT_TYPES.recommended && !missionId && !missionRoleId && !organizationId;

  const variables = {
    ...(applicantState === APPLICANT_TYPES.recommended
      ? {
          filters: {
            missionRoleId,
            missionId,
            organizationId,
            published,
          },
        }
      : {
          filters: {
            name: keyword,
            missionId,
            missionRoleId,
            organizationId,
            published,
            ...stateFilter,
          },
          ...(applicantState === APPLICANT_TYPES.applied && {
            orderBy: {
              appliedAt: DIRECTION.desc,
            },
          }),
        }),
  };

  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    error,
    data,
    refetch,
  } = useQueryData({
    queryName,
    keyName,
    resultsPerPage,
    paginationType,
    variables: {
      // For showcase or client we show estimates while for admins we show application rate
      includeSellingPriceEstimates: !isAdmin,
      ...variables,
    },
    skip: handleSkip || skipQuery,
  });

  useEffect(() => {
    setStateFilter(applicantState === null ? {} : { state: applicantState });
  }, [applicantState]);

  useEffect(() => {
    if (applicantState !== APPLICANT_TYPES.recommended) {
      refetch({
        filters: {
          name: keyword,
          missionId,
          missionRoleId,
          organizationId,
          published,
          ...stateFilter,
        },
      });
    }
  }, [keyword, missionId, missionRoleId, organizationId, stateFilter]);

  const getData = () => {
    if (applicantState === APPLICANT_TYPES.recommended) {
      /** We modify the data here to get user id's and state at the root level like other tabs. */
      return missionId || missionRoleId || organizationId
        ? data &&
            data[keyName]?.nodes?.map((item) => {
              return item.id ? item : { ...item, id: item?.profile?.id, state: applicantState };
            })
        : null;
    }
    return data && data[keyName]?.nodes;
  };

  const getError = () => {
    if (applicantState === APPLICANT_TYPES.recommended) {
      return missionId || missionRoleId || organizationId ? error : null;
    }
    return error;
  };

  const getAdvancedOptions = ({ id, missionRole }, selection) => {
    const type = APPLICANT_TYPE_VALUES.indexOf(applicantState);
    const ids = selection ? selection : [id];

    if (!ids.length || type < 0) return [];

    /**
     * Sets applicant to "unselected"
     */
    const removeOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToNotSelected" />,
      icon: ICON_TYPE.trash,
      onClick: () =>
        unselectedApplicant({
          variables: {
            input: { ids },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessNotSelected" />),
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "selected"
     */
    const selectedOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToSelected" />,
      icon: ICON_TYPE.checkmark,
      onClick: () =>
        selectedApplicant({
          variables: {
            input: { ids },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessSelected" />),
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "interviewing"
     */
    const interviewingOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToInterviewing" />,
      icon: ICON_TYPE.video,
      onClick: () =>
        interviewApplicant({
          variables: {
            input: { ids },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessInterviewing" />),
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "shorlisted"
     */
    const shortListOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToShortlist" />,
      icon: ICON_TYPE.plus,
      onClick: () =>
        shortlistApplicant({
          variables: {
            input: { ids },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessShortlist" />),
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    /**
     * Sets applicant to "invite"
     */
    const missionInviteMember = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToInvite" />,
      icon: ICON_TYPE.plus,
      onClick: () =>
        inviteApplicant({
          variables: {
            input: {
              missionRoleId: missionRole?.slug,
              profileIds: ids,
            },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessInvite" />),
          onError: () =>
            toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.ErrorInvite" />),
        }),
    };

    /**
     * Set applicant to "applied"
     */
    const appliedOption = {
      label: <FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.LabelAddToApplicants" />,
      icon: ICON_TYPE.email,
      onClick: () =>
        moveApplicantToApplied({
          variables: {
            input: { ids },
          },
          onCompleted: () =>
            toast.success(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.SuccessApplicants" />),
          onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicantAdvancedOptions.Error" />),
        }),
    };

    switch (type) {
      // selected
      case 0:
        return selection ? [] : [interviewingOption, removeOption];

      // interviewing
      case 1:
        return selection ? [removeOption] : [selectedOption, shortListOption, removeOption];

      // shortlist
      case 2:
        return selection ? [removeOption] : [interviewingOption, appliedOption, removeOption];

      // applied
      case 3:
        return [shortListOption, removeOption];

      // not selected
      case 4:
        return [shortListOption];

      // Recommendation tab
      case 5:
        return [missionInviteMember];

      // for all other tabs
      default:
        return [];
    }
  };

  return {
    data: getData(),
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    error: getError(),
    refetch,
    getAdvancedOptions,
    paginationType,
    resultsPerPage: data?.[keyName]?.perPage,
    count: {
      total: data?.[keyName]?.totalCount || 0,
      applied: data?.[keyName]?.appliedCount,
      interviewing: data?.[keyName]?.interviewingCount,
      invited: data?.[keyName]?.invitedCount,
      selected: data?.[keyName]?.selectedCount,
      shortlisted: data?.[keyName]?.shortlistedCount,
      unselected: data?.[keyName]?.unselectedCount,
    },
    pageInfo: data?.[keyName]?.pageInfo,
    currentPage: data?.[keyName]?.currentPage,
  };
}

const APPLICANT_TYPE_VALUES = [
  APPLICANT_TYPES.selected,
  APPLICANT_TYPES.interviewed,
  APPLICANT_TYPES.shortlisted,
  APPLICANT_TYPES.applied,
  APPLICANT_TYPES.unselected,
  APPLICANT_TYPES.recommended,
];

const QUERY_KEYNAMES = {
  applicants: "missionRoleApplicants",
  invitations: "missionRoleInvitations",
  recommendations: "missionRoleRecommendations",
};
