import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { ChangesText, KeyValueText } from "components/ActivityTooltip";
import { colors } from "style";

/**
 * CommitActivityTooltip
 *
 * @params {Object}      tooltipData
 */
const CommitActivityTooltip = ({ tooltipData }) => {
  const {
    id,
    diffAdditions,
    diffDeletions,
    diffTotalChanges,
    count,
    repository,
    isMembersView,
    totalDiffAdditions,
    totalDiffDeletions,
    totalDiffTotalChanges,
    repositories,
    users,
    user,
  } = tooltipData;

  return isMembersView ? (
    <>
      <div>
        <KeyValueText id="Commit" value={<span css={styles.hash}>{id.slice(0, 9)}</span>} />
      </div>
      <div>
        <ChangesText additions={diffAdditions} deletions={diffDeletions} changes={diffTotalChanges} />
      </div>
      <div>
        <KeyValueText id="Repository" value={repository} />
      </div>
      <div>
        <KeyValueText id="User" value={user} />
      </div>
    </>
  ) : (
    <>
      <div>
        <KeyValueText id="Count" value={count} />
      </div>
      <div>
        <ChangesText additions={totalDiffAdditions} deletions={totalDiffDeletions} changes={totalDiffTotalChanges} />
      </div>
      <div>
        <KeyValueText id="Repositories" value={repositories.join(", ")} />
      </div>
      <div>
        <KeyValueText id="Users" value={users.join(", ")} />
      </div>
    </>
  );
};

const styles = {
  hash: css`
    color: ${colors.grayAnatomyBase};
    background: ${colors.grayAnatomyLight3};
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    font-weight: 500;
  `,
};

CommitActivityTooltip.propTypes = {
  tooltipData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    diffAdditions: PropTypes.number,
    diffDeletions: PropTypes.number,
    diffTotalChanges: PropTypes.number,
    count: PropTypes.number,
    repository: PropTypes.string,
    isMembersView: PropTypes.bool,
    totalDiffAdditions: PropTypes.number,
    totalDiffDeletions: PropTypes.number,
    totalDiffTotalChanges: PropTypes.number,
    repositories: PropTypes.arrayOf(PropTypes.string),
    users: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.string,
  }),
};

export default CommitActivityTooltip;
