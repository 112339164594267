import { gql } from "@apollo/client";
import { PROFILE_ENTITY_FIELDS } from "graphql/fragments";

const GET_PULSE = gql`
  ${PROFILE_ENTITY_FIELDS}
  query pulse($teamId: ID!, $filters: PulseFilter!) {
    pulse(teamId: $teamId, filters: $filters) {
      profile {
        ...ProfileEntityFields
      }
      answer
      month
      monthVerbose
      question
      sentiment
      sentimentScore
    }
  }
`;

export { GET_PULSE };
