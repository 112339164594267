import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { GET_ASSIGNMENTS } from "graphql/queries";
import { formatDate } from "utils";
import { DataTableProvider } from "components/DataTable";
import { PreferredRateTag } from "components/Profile";
import { TAG_SIZE } from "components/Tags";
import { OrganizationLink } from "components/Organization";
import { SquadLink } from "components/Squads";
import { MissionLink } from "components/Missions";

/**
 * ApplicationPreviousAssignments
 *
 * @param {String} profileId
 */
const ApplicationPreviousAssignments = ({ profileId, ...props }) => {
  if (!profileId) return null;

  return (
    <DataTableProvider
      keyName="assignments"
      queryName={GET_ASSIGNMENTS}
      variables={{
        filters: {
          profileId,
          includeOffboardedAssignments: true,
        },
      }}
      columnData={[
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelOrganization" />,
          cell: (row) => <OrganizationLink data={row.team.organization} />,
        },
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelMission" />,
          cell: (row) => (row.team.mission ? <MissionLink data={row.team.mission} /> : "—"),
        },
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelTeam" />,
          cell: (row) => <SquadLink data={row.team} />,
        },
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelOnboardedAt" />,
          cell: (row) => <div>{formatDate(row.onboardedAt)}</div>,
        },
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelOffboardedAt" />,
          cell: (row) => <div>{formatDate(row.offboardedAt)}</div>,
        },
        {
          label: <FormattedMessage id="Missions.Applicants.ApplicationPreviousAssignments.LabelAssignedRate" />,
          cell: (row) => <PreferredRateTag rate={row.buyingRate} size={TAG_SIZE.medium} />,
        },
      ]}
      disablePagination
      {...props}
    />
  );
};

ApplicationPreviousAssignments.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default ApplicationPreviousAssignments;
