import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";
import { MissionAdvancedOptions, MissionAboutClient, MissionAbout } from "components/Missions";
import Card from "components/Card";
import { Grid } from "components/Containers";
import { RoleAddButton, RolesList } from "components/Missions/Roles";
import { useAuth } from "hooks";
import { SectionTitle } from "components/Layouts";

/**
 * Mission Page
 */
const MissionPage = () => {
  const [data, loading] = useOutletContext();
  const { isClient, isShowcase, canViewUnpublishedRoles } = useAuth();
  const mission = data?.mission;
  const intl = useIntl();

  const MissionBoardBackLink = () => {
    if (isClient || isShowcase) {
      return LINKS.client_mission_board;
    } else {
      return LINKS.mission_board;
    }
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Mission.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            backLink={
              <PrimaryLink to={MissionBoardBackLink()} routerEnabled>
                <FormattedMessage id="Missions.Mission.Role.Title" />
              </PrimaryLink>
            }
            title={mission?.name}
            actionBar={<MissionAdvancedOptions data={mission} />}
          />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <Card>
            <MissionAbout mission={mission} isLoading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Card>
            <MissionAboutClient mission={mission} isLoading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <SectionTitle
            title={<FormattedMessage id="Missions.Mission.RolesTitle" />}
            actionBar={<RoleAddButton missionId={mission?.uuid} />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <RolesList isCardView={true} missionId={mission?.uuid} filters={{ published: !canViewUnpublishedRoles }} />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default MissionPage;
