import React from "react";
import { FormattedMessage } from "react-intl";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { convertToPercent, formatCurrency, formatDate } from "utils";
import Status from "components/Status";
import { APPLICANT_TYPES, AVAILABILITIES, CAPACITY_STATUS, USER_TYPES } from "constants/index";
import { UserCapacity, UserFullName, UserLocation, UserPhoto } from "components/User";
import { ProfileLink, ProfileRateRange } from "components/Profile";
import Score from "components/Score";
import { RoleLink } from "components/Missions/Roles";
import { ScoreDisplay } from "components/Missions/Applicants";
import { ActionButton } from "components/Buttons";
import { colors } from "style";
import { Col, Row } from "components/Containers";
import Tooltip from "components/Tooltip";

const APPLICANT_LIST_COLUMNS = {
  member: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMember" />,
    width: "20%",
    cell: (row) => (
      <Row noWrap>
        <UserPhoto data={row?.profile} width="3rem" height="3rem" />
        <Col>
          <ProfileLink profile={row?.profile} withOverlay />
          <UserLocation data={row?.profile} />
        </Col>
      </Row>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  mission: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnRoleMissionOrg" />,
    width: "15%",
    cell: (row) => <RoleLink mission={row?.missionRole?.mission} missionRole={row?.missionRole} withOverlay />,
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  profileRate: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnProfileRate" />,
    width: "15%",
    cell: (row) => (
      <Col>
        <Row gap="0.25rem" noWrap>
          <Icon type={ICON_TYPE.coin} size={ICON_SIZE.medium} color="inherit" />
          <FormattedMessage
            id={row?.profile?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
            values={{
              amount: formatCurrency(row?.profile?.rate?.amount, row?.profile?.rate?.currency),
              type: (
                <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${row?.profile?.rate?.frequency}`} />
              ),
            }}
          />
        </Row>
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null, APPLICANT_TYPES.recommended],
    },
  },
  hourlyRateRange: {
    label: <FormattedMessage id="Missions.MissionCandidateList.HourlyRate" />,
    width: "15%",
    cell: ({ profile: { sellingPriceEstimate } }) => (
      <ProfileRateRange
        includesCommission={false}
        low={sellingPriceEstimate?.hourly?.low}
        high={sellingPriceEstimate?.hourly?.high}
        currency={sellingPriceEstimate?.currency}
        assignedSellingRate={false}
      />
    ),
    restrict: {
      roles: [USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  applicationRate: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnApplicationRate" />,
    width: "15%",
    cell: (row) => (
      <Col>
        {row?.rate?.amount ? (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ApplicantRate"}>
              <FormattedMessage
                id={row?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.rate?.amount, row?.rate?.currency),
                  type: <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${row?.rate?.frequency}`} />,
                }}
              />
            </Tooltip>
          </Row>
        ) : (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.coin} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ProfileRate"}>
              <FormattedMessage
                id={row?.profile?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.profile?.rate?.amount, row?.profile?.rate?.currency),
                  type: (
                    <FormattedMessage
                      id={`Profile.ProfileRateAndAvailability.Frequency${row?.profile?.rate?.frequency}`}
                    />
                  ),
                }}
              />
            </Tooltip>
          </Row>
        )}
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  noticePeriod: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnNoticePeriod" />,
    width: "10%",
    cell: (row) =>
      !row.noticePeriod ? (
        <FormattedMessage id="Global.NotProvided" />
      ) : (
        AVAILABILITIES.find((e) => e.value === row.noticePeriod).label
      ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  profileAvailability: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAvailability" />,
    width: "15%",
    cell: (row) => (
      <Status enabled={row?.profile?.capacityStatus === CAPACITY_STATUS.available}>
        <UserCapacity data={row?.profile} />
      </Status>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [null, APPLICANT_TYPES.recommended],
    },
  },
  invited: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnInvitedBy" />,
    width: "15%",
    cell: (row) => (
      <Col>
        <UserFullName data={row?.invitedBy} />
        <span>{formatDate(row?.invitedAt)}</span>
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null],
    },
  },
  applied: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAppliedAt" />,
    width: "10%",
    cell: (row) => formatDate(row?.appliedAt),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  matchScore: {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "10%",
    cell: (row) => (
      <Tooltip tooltipId="Global.Text" values={{ text: <ScoreDisplay subScores={row?.subScores} /> }}>
        <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>
      </Tooltip>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  matchScoreWithoutTooltip: {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "10%",
    cell: (row) => <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>,
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null],
    },
  },
  rateScore: {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore" />,
    width: "10%",
    cell: (row) => <Score score={Number(row?.rateScore) * 100}>{convertToPercent(row?.rateScore)}</Score>,
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  viewButton: ({ setApplication }) => ({
    width: 70,
    cell: (row) => (
      <ActionButton onClick={() => setApplication(row)}>
        <Icon type={ICON_TYPE.eye} size={ICON_SIZE.large} color={colors.purpleRainBase} />
      </ActionButton>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  }),
};

export default APPLICANT_LIST_COLUMNS;
